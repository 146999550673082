// Element.prototype.matches polyfill
if(!Element.prototype.matches)Element.prototype.matches=Element.prototype.msMatchesSelector||Element.prototype.webkitMatchesSelector;if(!Element.prototype.closest){Element.prototype.closest=function(s){var el=this;if(!document.documentElement.contains(el))return null;do{if(el.matches(s))return el;el=el.parentElement||el.parentNode}while(el!==null&&el.nodeType===1);return null}}
// Array.from pollyfill
if(!Array.from){Array.from=function(){var toStr=Object.prototype.toString;var isCallable=function(fn){return typeof fn==="function"||toStr.call(fn)==="[object Function]"};var toInteger=function(value){var number=Number(value);if(isNaN(number)){return 0}if(number===0||!isFinite(number)){return number}return(number>0?1:-1)*Math.floor(Math.abs(number))};var maxSafeInteger=Math.pow(2,53)-1;var toLength=function(value){var len=toInteger(value);return Math.min(Math.max(len,0),maxSafeInteger)};return function from(arrayLike){var C=this;var items=Object(arrayLike);if(arrayLike==null){throw new TypeError("Array.from requires an array-like object - not null or undefined")}var mapFn=arguments.length>1?arguments[1]:void undefined;var T;if(typeof mapFn!=="undefined"){if(!isCallable(mapFn)){throw new TypeError("Array.from: when provided, the second argument must be a function")}if(arguments.length>2){T=arguments[2]}}var len=toLength(items.length);var A=isCallable(C)?Object(new C(len)):new Array(len);var k=0;var kValue;while(k<len){kValue=items[k];if(mapFn){A[k]=typeof T==="undefined"?mapFn(kValue,k):mapFn.call(T,kValue,k)}else{A[k]=kValue}k+=1}A.length=len;return A}}()}

document.addEventListener('DOMContentLoaded', function() {
    var el = document.body;

    function pageScrolled() {
        if (!el.classList.contains('scrolled') && window.scrollY > 0) {
            el.classList.add('scrolled');
        } else if (window.scrollY === 0) {
            el.classList.remove('scrolled');
        }
    }

    function teamworkScrolled() {
        var teamworkEl = document.getElementById('teamwork').querySelector('.container.scroll');
        var teamworkTextEl = teamworkEl.querySelector('.text-wrapper');
        var rect = teamworkEl.getBoundingClientRect();
        var rectTeam = teamworkTextEl.getBoundingClientRect();
        if (rect.top < 0 && rect.height < rectTeam.height + Math.abs(rect.top)) {
            teamworkTextEl.style.top = '200vh';
            teamworkTextEl.style.position = 'absolute';
            teamworkTextEl.style.left = teamworkEl.style.paddingLeft;
            return;
        }
        if (rect.top < 0) {
            teamworkTextEl.style.top = 0;
            teamworkTextEl.style.position = 'fixed';
            teamworkTextEl.style.left = (rect.x + 15) + 'px';
        } else {
            teamworkTextEl.style.top = 0;
            teamworkTextEl.style.position = 'absolute';
            teamworkTextEl.style.left = teamworkEl.style.paddingLeft;
        }
    }

    function openEarlyAccessDialog(event) {
        event.preventDefault();
        document.body.classList.add('dialog-open');
        dialogBackdrop.classList.remove('hidden');
        dialogEarlyAccess.classList.add('open');
        window.addEventListener('keydown', earlyAccessDialogEsc);
    }

    function closeEarlyAccessDialog() {
        document.body.classList.remove('dialog-open');
        dialogBackdrop.classList.add('hidden');
        dialogEarlyAccess.classList.remove('open');
        clearForm();
        touchedInputs = [];
        hideLoading();
        hideSuccess();
        hideError();
        window.removeEventListener('keydown', earlyAccessDialogEsc);
    }

    function earlyAccessDialogEsc(event) {
        if (event.keyCode === 27) {
            closeEarlyAccessDialog();
        }
    }


    var formEarlyAccess = document.getElementById('form-early-access');
    var dialogEarlyAccess = document.getElementById('dialog-early-access');
    var btnEarlyAccess = Array.from(document.querySelectorAll('.js-early-access'));
    var dialogBackdrop = document.getElementById('dialog-backdrop');

    var inputName = document.getElementById('input-name');
    var inputEmail = document.getElementById('input-email');
    var inputNumber = document.getElementById('input-number');
    var inputCompany = document.getElementById('input-company');
    var inputAgree = document.getElementById('input-agree');
    var formSubmit = document.getElementById('form-submit');


    var prevValidation = false;

    function clearForm() {
        inputName.value = '';
        inputEmail.value = '';
        inputNumber.value = '';
        inputCompany.value = '';
        inputAgree.checked = false;
    }

    function submitForm(e) {
        var data = {
            fullname: inputName.value,
            email: inputEmail.value,
            mobile: inputNumber.value,
            company: inputCompany.value,
            // agreed: inputAgree.checked,
        };
        var url = 'https://tellq.io/mail/send.php';

        var oReq = new XMLHttpRequest();

        oReq.addEventListener('load', function() {
            // success
            hideLoading();
            showSuccess();
            setTimeout(function() {
                hideSuccess();
            }, 3000);
        });
        oReq.addEventListener('error', function() {
            // error
            hideLoading();
            showError();
            setTimeout(function() {
                hideError();
            }, 3000);
        });

        oReq.open('POST', url);

        oReq.send(JSON.stringify(data));

        showLoading();
    }

    var formLoading = document.getElementById('form-loading');
    var formSuccess = document.getElementById('form-success');
    var formError = document.getElementById('form-error');
    function showLoading() {
        formLoading.classList.remove('hidden');
    }
    function hideLoading() {
        formLoading.classList.add('hidden');
    }
    function showSuccess() {
        formSuccess.classList.remove('hidden')
    }
    function hideSuccess() {
        formSuccess.classList.add('hidden');
    }
    function showError() {
        formError.classList.remove('hidden');
    }
    function hideError() {
        formError.classList.add('hidden');
    }
    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    var touchedInputs = [];

    function validateForm(e) {
        if (e.target && e.target.id) {
            if (touchedInputs.indexOf(e.target.id) === -1) {
                touchedInputs.push(e.target.id);
            }
        }
        var validName = inputName && !!inputName.value;
        var validEmail = inputEmail && inputEmail.value && validateEmail(inputEmail.value);
        var validNumber = inputNumber && inputNumber.value && RegExp(/\d+/gm).test(String(inputNumber.value));
        var validCompany = inputCompany && !!inputCompany.value;
        var validAgree = inputAgree && inputAgree.checked;

        if (!validName && touchedInputs.indexOf('input-name') !== -1) {
            inputName.closest('.input-row').classList.add('has-error');
        } else {
            inputName.closest('.input-row').classList.remove('has-error');
        }

        if (!validEmail && touchedInputs.indexOf('input-email') !== -1) {
            inputEmail.closest('.input-row').classList.add('has-error');
        } else {
            inputEmail.closest('.input-row').classList.remove('has-error');
        }

        if (!validNumber && touchedInputs.indexOf('input-number') !== -1) {
            inputNumber.closest('.input-row').classList.add('has-error');
        } else {
            inputNumber.closest('.input-row').classList.remove('has-error');
        }

        if (!validCompany && touchedInputs.indexOf('input-company') !== -1) {
            inputCompany.closest('.input-row').classList.add('has-error');
        } else {
            inputCompany.closest('.input-row').classList.remove('has-error');
        }

        var newValidation = validName && validEmail && validNumber && validCompany && validAgree;
        if (prevValidation === newValidation) {
            return;
        } else {
            prevValidation = newValidation;
        }

        if (newValidation) {
            formSubmit.removeAttribute('disabled');
        } else {
            formSubmit.setAttribute('disabled', true);
        }

    }

    function bindEvents() {
        window.addEventListener('scroll', function (e) {
            pageScrolled();
            teamworkScrolled();
        });

        window.addEventListener('resize', function (e) {
            teamworkScrolled();
        });

        if (formEarlyAccess) {
            formEarlyAccess.addEventListener('submit', function(e) {
                e.preventDefault();
                submitForm(e);
            });
            formEarlyAccess.addEventListener('change', validateForm);
            formEarlyAccess.addEventListener('keyup', validateForm);
        }

        var earlyAccessButtons = Array.from(document.querySelectorAll('.js-early-access'));
        if (earlyAccessButtons) {
            earlyAccessButtons.forEach(function(btn) {
                btn.addEventListener('click', openEarlyAccessDialog);
            });
        }

        var dialogClose = Array.from(document.querySelectorAll('.dialog-close'));
        if (dialogClose) {
            dialogClose.forEach(function(close) {
                close.addEventListener('click', function() {
                    closeEarlyAccessDialog();
                });
            })
        }

    }


    function main() {
        pageScrolled();
        teamworkScrolled();
        bindEvents();
        document.body.classList.remove('beforejs');
        AOS.init({
            once: true,
        });
    }
    main();
});
